import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private notificationSubject = new Subject<{
    message: string;
    type: 'info' | 'success' | 'warning' | 'error';
  }>();
  notification$ = this.notificationSubject.asObservable();

  showNotification(
    message: string,
    type: 'info' | 'success' | 'warning' | 'error' = 'info'
  ) {
    this.notificationSubject.next({ message, type });
  }
}
