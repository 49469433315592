import { NgClass, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DELAY_TIME } from '@constants/common';
import { NotificationService } from '@services/notification.service';

@Component({
  selector: 'app-notification',
  standalone: true,
  imports: [NgIf, NgClass],
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  message: string | null = null;
  notificationType: 'info' | 'success' | 'warning' | 'error' = 'info';

  constructor(private notificationService: NotificationService) {}

  ngOnInit() {
    this.notificationService.notification$.subscribe(({ message, type }) => {
      this.message = message;
      this.notificationType = type || 'info';
      setTimeout(() => (this.message = null), DELAY_TIME['4000_MS']);
    });
  }
}
