import { Routes } from '@angular/router';
import { AudioChatComponent } from '@pages/audio-chat/audio-chat.component';
import { AudioVisualizerComponent } from '@pages/audio-visualizer/audio-visualizer.component';
import { NotAvailableComponent } from '@pages/not-available/not-available.component';
import { NotFoundComponent } from '@pages/not-found/not-found.component';
import { SmartSurveyComponent } from '@pages/smart-survey/smart-survey.component';
import { VerticalKioskComponent } from '@pages/vertical-kiosk/vertical-kiosk.component';
import { VideoBgComponent } from '@pages/video-bg/video-bg.component';

export const appRoutes: Routes = [
  {
    path: '',
    component: SmartSurveyComponent,
  },
  {
    path: 'audio-chat',
    component: AudioChatComponent,
  },
  {
    path: 'video-chat',
    component: VideoBgComponent,
  },
  {
    path: 'kiosk',
    component: VerticalKioskComponent,
  },
  {
    path: 'smart-survey',
    component: SmartSurveyComponent,
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
  },
  {
    path: 'au-viz',
    component: AudioVisualizerComponent,
  },
  { path: 'not-available', component: NotAvailableComponent },
  {
    path: '**',
    redirectTo: 'not-found',
    pathMatch: 'full',
    data: {
      title: 'Page Not Found',
    },
  },
];
