import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AudioVisualizerService {
  private colorSource = new BehaviorSubject<string>('white'); // default color
  currentColor = this.colorSource.asObservable();

  constructor() {}

  changeColor(color: string) {
    this.colorSource.next(color);
  }
}
