import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DELAY_TIME, MAGIC_NUMBERS } from '@constants/common';
import {
  ChatMessage,
  ComponentState,
  TypeRes,
} from '@models/speech-recognition.model';
import { LoggerService } from '@services/logger.service';
import { StateManagementService } from '@services/state-management.service';
import { VoiceRecognitionService } from '@services/voice-recognition.service';
import { WebSocketService } from '@services/web-socket.service';
import { ThinkingLoaderComponent } from '@shared/thinking-loader/thinking-loader.component';
import { Subject, Subscription, delay, of, takeUntil } from 'rxjs';

@Component({
  selector: 'app-video-bg',
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    NgClass,
    NgForOf,
    ThinkingLoaderComponent,
    HttpClientModule,
  ],
  templateUrl: './video-bg.component.html',
  styleUrls: ['./video-bg.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 1, transform: 'translateY(0)' })),
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(20px)' }),
        animate('400ms ease-out'),
      ]),
      transition(':leave', [
        animate(
          '400ms ease-out',
          style({ opacity: 0, transform: 'translateY(-20px)' })
        ),
      ]),
    ]),
  ],
})
export class VideoBgComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('videoElement') videoElement: ElementRef<HTMLVideoElement>;
  @ViewChild('chatHistoryContainer', { static: false })
  private chatHistoryContainer: ElementRef<HTMLDivElement>;

  private destroy$ = new Subject<void>();
  private stateSub: Subscription;

  videoSrc = 'assets/videos/input.mp4';
  placeholder = 'assets/images/bg-placeholder.png';
  showWelcomeMessage = true;
  videoError = false;
  hasChatHistory = false;
  showChat = false;
  inputText = '';
  showSendButton = false;
  countDownInterval: NodeJS.Timeout;
  countdown = 3;
  currentState: ComponentState = 'welcome';
  isPermission = false;
  isDecline = false;
  isActiveStream = true;
  isDisconnectAlert = false;
  answer = '';
  voiceInput = '';
  baseUrl = '';
  shouldLoop = true;
  chatHistory: ChatMessage[] = [];
  currentInputMode: 'text' | 'speech' = 'text';

  constructor(
    private stateService: StateManagementService,
    private voiceRecognition: VoiceRecognitionService,
    private loggerService: LoggerService,
    private webSocketService: WebSocketService
  ) {
    // Connect to WebSocket for video chat
    this.webSocketService.connect('video');
  }

  ngOnInit(): void {
    this.baseUrl = window.location.origin;
    this.isPermission = JSON.parse(
      localStorage.getItem('userVisited') ?? 'false'
    );

    this.stateSub = this.stateService.viewState$.subscribe((state) => {
      this.currentState = state;
    });
  }

  ngAfterViewInit() {
    this.initializeVideoPlayback();
  }

  scrollToBottom(): void {
    if (this.hasChatHistory && this.chatHistoryContainer) {
      const container = this.chatHistoryContainer.nativeElement;
      container.scrollTop = container.scrollHeight; // Always scroll to the bottom
    }
  }

  initializeVideoPlayback() {
    const video: HTMLVideoElement = this.videoElement.nativeElement;

    if (!video) {
      return;
    }

    video.oncanplaythrough = () => {
      if (
        video.currentTime === MAGIC_NUMBERS['0'] &&
        (video.paused || video.ended)
      ) {
        video.muted = true;

        if (video.controls) {
          video.controls = false;
          video.setAttribute('controlsList', 'nodownload');
        }
        video.play().catch((err) => this.loggerService.displayLog(err));
      }
    };
  }

  startRecording() {
    if (!this.isPermission) return;

    of({})
      .pipe(delay(DELAY_TIME['500_MS']))
      .subscribe(() => {
        this.startListening();
        this.voiceRecognition
          .startRecording()
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: (blob) => {
              this.voiceRecognition
                .transcribeAudioWithOpenAI(blob)
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                  next: (text) => {
                    this.voiceInput = text.text;
                    if (this.voiceInput) {
                      this.startSubmitting();
                    } else {
                      this.resetRecording();
                    }
                  },
                  error: (err) => {
                    this.loggerService.displayLog(err);
                    this.stopRecording();
                  },
                });
            },
            error: (err) => {
              this.loggerService.displayLog(err);
              this.stopRecording();
            },
          });
      });
  }

  startSubmitting() {
    this.stateService.setViewState(
      this.currentState === 'submitting' ? 'none' : 'submitting'
    );

    this.submitSpeechCountdown();
  }

  submitSpeechCountdown() {
    const countTime = 1000;

    this.countDownInterval = setInterval(() => {
      if (this.countdown > 1) {
        this.countdown--;
      } else {
        clearInterval(this.countDownInterval);
        this.submitSpeech();
        this.stopRecording();
      }
    }, countTime);
  }

  submitSpeech() {
    if (!this.inputText.trim().length && !this.voiceInput.length) return;

    if (this.inputText.length) {
      this.setTextInputMode();
    } else {
      this.setSpeechInputMode();
    }

    const message = this.voiceInput || this.inputText;

    // Add outgoing message to chat history
    this.chatHistory.push({ sender: 'user', message: message });
    this.scrollToBottom();

    // Start thinking animation
    this.startThinking();

    // Send message to WS
    this.webSocketService.send({ question: message });

    // Listen for the response
    this.getSocketInformation();

    this.hasChatHistory = true;
    this.voiceInput = '';
    this.inputText = '';
  }

  private setTextInputMode() {
    this.currentInputMode = 'text';
  }

  private setSpeechInputMode() {
    this.currentInputMode = 'speech';
  }

  startThinking() {
    this.stateService.setViewState('thinking');
  }

  getSocketInformation() {
    this.webSocketService.socket$.subscribe({
      next: (res: TypeRes) => {
        this.videoError = false;
        this.videoSrc = res.object_link as string;

        // Add response to chat history
        if (
          res.answer &&
          this.chatHistory[this.chatHistory.length - 1].message !== res.answer
        ) {
          this.chatHistory.push({ sender: 'server', message: res.answer });
          this.shouldLoop = false;
        }

        const video: HTMLVideoElement = this.videoElement.nativeElement;
        video.muted = false;

        this.hasChatHistory = true;
        this.responseSubmitted();
      },
      error: (error) => {
        this.loggerService.displayLog(error);
      },
    });
  }

  responseSubmitted() {
    this.clearIntervalAndResetCountdown();

    if (this.hasChatHistory) this.toggleChat(); // Reopen chat history after thinking
  }

  clearIntervalAndResetCountdown() {
    if (this.countDownInterval) clearInterval(this.countDownInterval);
    this.countdown = 3;
  }

  stopRecording() {
    if (this.countDownInterval) {
      clearInterval(this.countDownInterval);
    }

    this.voiceInput?.length && this.showWelcome();

    this.voiceInput = '';
    this.countdown = 3;

    this.cancelOngoingRequests();
    this.voiceRecognition.cleanup();
  }

  completeRecording() {
    if (this.countDownInterval) {
      clearInterval(this.countDownInterval);
    }

    this.showWelcome();

    this.voiceInput = '';
    this.countdown = 3;

    this.cancelOngoingRequests();
    this.voiceRecognition.cleanup();
  }

  cancelOngoingRequests() {
    this.destroy$.next();
  }

  resetRecording() {
    this.voiceRecognition.stopRecording();

    this.voiceInput = '';
    this.resetControls();

    if (this.countDownInterval) {
      clearInterval(this.countDownInterval);
    }
    this.countdown = 3;

    // Start a new recording
    setTimeout(() => {
      this.startRecording();
    }); // Optional delay to ensure all states are reset
  }

  reloadModal() {
    location.reload();
  }

  stopRespond() {
    this.voiceInput = '';
    this.inputText = '';
    this.resetControls();
  }

  onVideoError(): void {
    this.videoError = true;
  }

  toggleChat(): void {
    this.showChat = !this.showChat;
    this.stateService.setViewState(
      this.currentState === 'chat' ? 'welcome' : 'chat'
    );

    if (this.showChat) {
      setTimeout(() => this.scrollToBottom(), DELAY_TIME['100_MS']); // Use setTimeout to allow the DOM to update
    }
  }

  showWelcome() {
    this.stateService.setViewState('welcome');
  }

  startListening() {
    this.stateService.setViewState(
      this.currentState === 'listening' ? 'none' : 'listening'
    );
  }

  checkInput(): void {
    this.showSendButton = !!this.inputText.length;
  }

  shouldShowStopListening(): boolean {
    return ['listening', 'submitting', 'thinking'].includes(this.currentState);
  }

  getMicPermission() {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        this.isActiveStream = stream.active;
        if (this.isActiveStream) {
          this.isPermission = true;
          this.reloadModal();
        }
        this.isDecline = false;
        localStorage.setItem('userVisited', JSON.stringify(this.isPermission));
      })
      .catch((error) => {
        this.isPermission = false;
        this.isDecline = true;
        this.loggerService.displayLog(
          'Error getting microphone permission:',
          error
        );
      });
  }

  resetControls() {
    this.stateService.setViewState('welcome');
    this.showChat = false;
  }

  onVideoEnded(): void {
    this.videoSrc = 'assets/videos/input.mp4';
    this.shouldLoop = true;

    if (this.currentInputMode === 'speech') {
      this.startRecording();
    } else if (this.currentInputMode === 'text') {
      this.stateService.setViewState('none');
    } else {
      this.stateService.setViewState('none');
    }
  }

  ngOnDestroy() {
    localStorage.removeItem('userVisited');
    this.destroy$.next();
    this.destroy$.complete();
    this.stateSub.unsubscribe();
    this.webSocketService.disconnect();
  }
}
