import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DELAY_TIME,
  DeepgramConfig,
  MAGIC_NUMBERS,
  OpenAIConfig,
} from '@constants/common';
import {
  DeepgramResponse,
  WhisperResponse,
} from '@models/speech-recognition.model';
import { Observable, Subject, Subscriber, interval } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class VoiceRecognitionService {
  private mediaRecorder: MediaRecorder;
  private audioContext: AudioContext | null = null;
  private analyser: AnalyserNode | null = null;
  private audioChunks: BlobPart[] = [];
  private silenceTimer$: ReturnType<typeof setTimeout> | undefined;

  constructor(private http: HttpClient) {}

  startRecording(): Observable<Blob> {
    this.audioContext = new AudioContext();
    this.audioChunks = [];
    return new Observable<Blob>((observer) => {
      navigator.mediaDevices
        .getUserMedia({ audio: { noiseSuppression: true } })
        .then((stream) => this.setupRecorder(stream, observer))
        .catch((error) => observer.error(error));
    });
  }

  private setupRecorder(stream: MediaStream, observer: Subscriber<Blob>): void {
    if (!this.audioContext) {
      observer.error('Audio context is not initialized');
      return;
    }

    const source = this.audioContext.createMediaStreamSource(stream);
    this.analyser = this.audioContext.createAnalyser();
    source.connect(this.analyser);

    this.setupMediaRecorder(stream, observer);
    this.setupSilenceDetection();
  }

  private setupMediaRecorder(
    stream: MediaStream,
    observer: Subscriber<Blob>
  ): void {
    this.mediaRecorder = new MediaRecorder(stream);
    this.mediaRecorder.ondataavailable = (event: BlobEvent) => {
      this.audioChunks.push(event.data);
    };
    this.mediaRecorder.onstop = () => {
      const audioBlob = new Blob(this.audioChunks, {
        type: OpenAIConfig.fileType,
      });
      if (this.audioChunks.length > 0) {
        observer.next(audioBlob);
      }
      observer.complete();
      this.sessionCleanup(stream);
    };
    this.mediaRecorder.start(DELAY_TIME['1000_MS']);
  }

  private setupSilenceDetection(): void {
    const dataArray = new Uint8Array(this.analyser!.frequencyBinCount);
    interval(DELAY_TIME['100_MS']).subscribe(() => {
      if (!this.analyser) return;
      this.analyser.getByteTimeDomainData(dataArray);
      this.evaluateSilence(dataArray);
    });
  }

  private evaluateSilence(dataArray: Uint8Array): void {
    const sum = dataArray.reduce(
      (acc, value) =>
        acc + (value - MAGIC_NUMBERS['128']) ** MAGIC_NUMBERS['2'],
      0
    );
    const average = Math.sqrt(sum / dataArray.length);
    if (average < MAGIC_NUMBERS['6']) {
      // Adjusted silence threshold
      this.startSilenceTimer();
    } else {
      this.clearSilenceTimer();
    }
  }

  private startSilenceTimer(): void {
    if (!this.silenceTimer$) {
      this.silenceTimer$ = setTimeout(
        () => this.stopRecording(),
        DELAY_TIME['2000_MS']
      );
    }
  }

  private clearSilenceTimer(): void {
    if (this.silenceTimer$) {
      clearTimeout(this.silenceTimer$);
      this.silenceTimer$ = undefined;
    }
  }

  stopRecording(): void {
    if (this.mediaRecorder && this.mediaRecorder.state === 'recording') {
      this.mediaRecorder.stop(); // This will trigger onstop and clean up
    }
  }

  cleanup() {
    if (this.mediaRecorder?.stream) {
      this.stopRecording();
      this.sessionCleanup(this.mediaRecorder?.stream);
    }
  }

  private sessionCleanup(stream: MediaStream): void {
    stream.getTracks().forEach((track) => track.stop());
    if (this.audioContext) {
      this.audioContext.close();
      this.audioContext = null;
      this.analyser = null;
    }
    this.clearSilenceTimer();
  }

  transcribeAudioWithDeepgram(blob: Blob): Observable<DeepgramResponse> {
    const formData = new FormData();
    formData.append('file', blob);

    // Prepare the keywords query parameter
    const keywordsParam = ['ViitorCloud', 'ViitorCloud Technologies'].join(',');

    return this.http.post<DeepgramResponse>(
      `https://api.deepgram.com/v1/listen?keywords=${keywordsParam}`,
      formData,
      {
        headers: {
          Authorization: `Token ${DeepgramConfig.apiKey}`,
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  }

  transcribeAudioWithOpenAI(blob: Blob): Observable<WhisperResponse> {
    const formData = new FormData();
    formData.append('file', blob);
    formData.append('model', OpenAIConfig.model);
    formData.append('language', 'en');
    formData.append('temperature', '0');
    formData.append(
      'prompt',
      `The sentence may be cut off, do not make up words to fill in the rest of the sentence.
    Haxi, ViitorCloud, Technologies, ViitorCloud Technologies, CEO, CTO, CFO, EveryCRED, Klaviss.`
    );

    return this.http.post<WhisperResponse>(OpenAIConfig.baseUrl, formData, {
      headers: {
        Authorization: `Bearer ${OpenAIConfig.apiKey}`,
      },
    });
  }

  private bypassDollarStrings(s: string) {
    const pattern = /^(?!.*\$\$).*/;
    return pattern.test(s);
  }

  handleRecording(destroy$: Subject<void>): Observable<string> {
    return new Observable<string>((observer) => {
      const startTranscription = () => {
        this.startRecording()
          .pipe(takeUntil(destroy$))
          .subscribe({
            next: (blob) => {
              this.transcribeAudio(
                blob,
                observer,
                startTranscription,
                destroy$
              );
            },
            error: (err) => {
              observer.error(err);
              startTranscription();
            },
          });
      };

      startTranscription();
    });
  }

  private transcribeAudio(
    blob: Blob,
    observer: Subscriber<string>,
    retryTranscription: () => void,
    destroy$: Subject<void>
  ): void {
    this.transcribeAudioWithOpenAI(blob)
      .pipe(takeUntil(destroy$))
      .subscribe({
        next: (text) => {
          const transcript = text.text;
          if (transcript && this.bypassDollarStrings(transcript)) {
            observer.next(transcript);
            observer.complete();
          } else {
            // Restart recording if transcript is empty
            retryTranscription();
          }
        },
        error: (err) => {
          observer.error(err);
          retryTranscription();
        },
      });
  }
}
