import { DecimalPipe, NgClass, NgFor, NgIf } from '@angular/common';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { WakeLockService } from '@app/core/services/wake-lock.service';
import { DELAY_TIME, MAGIC_NUMBERS } from '@constants/common';
import { TypeRes } from '@models/speech-recognition.model';
import { AudioVisualizerComponent } from '@pages/audio-visualizer/audio-visualizer.component';
import { LoggerService } from '@services/logger.service';
import { NotificationService } from '@services/notification.service';
import { TextToSpeechService } from '@services/text-to-speech.service';
import { VoiceRecognitionService } from '@services/voice-recognition.service';
import { WebSocketService } from '@services/web-socket.service';
import { Subject, of } from 'rxjs';

interface Question {
  question: string;
  options: string[];
  required: boolean;
}

@Component({
  selector: 'app-smart-survey',
  standalone: true,
  imports: [DecimalPipe, NgFor, NgIf, NgClass, AudioVisualizerComponent],
  templateUrl: './smart-survey.component.html',
  styleUrls: ['./smart-survey.component.scss'],
})
export class SmartSurveyComponent implements OnInit, OnDestroy {
  @ViewChild('questionContainer', { static: false })
  private questionContainer: ElementRef<HTMLDivElement>;

  currentStep: 'landing' | 'question' | 'summary' = 'landing';
  questions: Question[] = [
    {
      required: true,
      question: 'Please select your age group.',
      options: ['A. Under 21', 'B. 21 to 40', 'C. 41 to 60', 'D. Above 60'],
    },
    {
      required: true,
      question: 'How often do you use our products and services?',
      options: ['A. Daily', 'B. Weekly', 'C. Monthly', 'D. Yearly'],
    },
    {
      required: false,
      question:
        'How well did our products and services meet your expectations?',
      options: [
        'A. Extremely well',
        'B. Somewhat well',
        'C. Not so well',
        "D. Can't say",
      ],
    },
    {
      required: false,
      question: 'Which product do you like the most?',
      options: [
        'A. EveryTicket',
        'B. Digital Humans',
        'C. Cuenect',
        'D. EveryCred',
      ],
    },
    {
      required: false,
      question:
        'Overall, how satisfied or dissatisfied are you with our company?',
      options: [
        'A. Very satisfied',
        'B. Somewhat satisfied',
        'C. Neutral',
        'D. Strongly dissatisfied',
      ],
    },
    {
      required: false,
      question:
        'How likely is it that you would recommend our product to your connections?',
      options: [
        'A. Very likely',
        'B. Somewhat likely',
        'C. Neutral',
        'D. Very unlikely',
      ],
    },
  ];
  currentQuestionIndex = 0;
  selectedOption: string | null = null;
  answers: {
    question: string;
    answer: string | null;
  }[] = [];
  isPermission = false;
  voiceInput: string;
  isActiveStream = true;
  baseUrl = '';
  isDecline: boolean;
  textToSpeak: Blob;
  textToSpeakReady = false;
  openPopup = true;
  welcomeBlob: Blob;
  welcomeBlobReady = false;
  showAudioViz = false;
  isMenuOpen = false;
  progress = 0;
  currentOption = 'bothOn';
  questionIsRequired = false;

  private destroy$ = new Subject<void>();
  private currentAudio: HTMLAudioElement | null = null;
  private globalClickListener: () => void;

  constructor(
    private loggerService: LoggerService,
    private notificationService: NotificationService,
    private voiceRecognition: VoiceRecognitionService,
    private webSocketService: WebSocketService,
    private ttsService: TextToSpeechService,
    private renderer: Renderer2,
    private wakeLockService: WakeLockService
  ) {}

  ngOnInit(): void {
    this.wakeLockService.requestWakeLock();
    this.baseUrl = window.location.origin;
    this.isPermission = JSON.parse(
      localStorage.getItem('userVisited') ?? 'false'
    );

    if (!this.isPermission && this.isActiveStream) {
      this.openPopup = false;
    }

    const welcomeText = `Welcome to our product survey!
    This survey will take approximately 5 minutes to complete.
    Please read the instructions carefully and Start the survey.`;

    this.ttsService.generateSpeech(welcomeText).subscribe({
      next: (blob) => {
        this.welcomeBlob = blob;
      },
      error: (err) => this.loggerService.displayLog(err),
      complete: () => {
        this.welcomeBlobReady = true;
        this.introFirstQuestion();
      },
    });
    this.connectWebSocket();
    this.getSocketInformation();
  }

  welcomeMessage() {
    this.openPopup = false;
    if (
      this.currentOption === 'bothOn' ||
      this.currentOption === 'narrationOn'
    ) {
      this.playAudioBlob(this.welcomeBlob);
    }
  }

  introFirstQuestion() {
    const introText = `Hey there! Let's start with our first question. ${this.questions[0].question}
    and the options are ${this.questions[0].options}`;
    this.textToSpeech(introText);
  }

  private textToSpeech(text: string) {
    if (this.currentOption === 'bothOff' || this.currentOption === 'speechOn') {
      return;
    }

    this.ttsService.generateSpeech(text).subscribe({
      next: (blob) => {
        this.textToSpeak = blob;
        this.textToSpeakReady = true;
      },
      error: (err) => {
        this.loggerService.displayLog(err);
        this.textToSpeakReady = false;
      },
    });
  }

  private playAudioBlob(blob: Blob, onEndedCallback?: () => void) {
    if (this.currentOption === 'bothOff' || this.currentOption === 'speechOn') {
      return;
    }

    this.stopNarration();
    const url = URL.createObjectURL(blob);
    const audio = new Audio(url);
    this.currentAudio = audio;
    audio.play();
    if (onEndedCallback) {
      audio.onended = onEndedCallback;
    }
  }

  private connectWebSocket(): void {
    this.webSocketService.connectSmartSurvey('audio');
  }

  startSurvey() {
    this.currentStep = 'question';

    if (
      this.currentOption === 'bothOn' ||
      this.currentOption === 'narrationOn'
    ) {
      this.haxiSpeaking();
    } else if (
      this.currentOption === 'bothOff' ||
      this.currentOption === 'speechOn'
    ) {
      this.startRecording();
    }
  }

  haxiSpeaking() {
    if (this.textToSpeakReady) {
      this.playAudioBlob(this.textToSpeak, this.startRecording.bind(this));
      this.textToSpeakReady = false;
    }
  }

  selectOption(option: string) {
    this.selectedOption = option;
    navigator.vibrate(MAGIC_NUMBERS['100']);

    if (this.currentOption !== 'bothOff') {
      this.stopNarration();
      this.completeQuestion();
    }
  }

  nextQuestion() {
    if (
      this.questions[this.currentQuestionIndex].required &&
      !this.selectedOption
    ) {
      this.questionIsRequired = true;
      this.questionContainer.nativeElement?.classList.add('horizontal-shake');

      setTimeout(() => {
        this.questionContainer.nativeElement?.classList.remove(
          'horizontal-shake'
        );
      }, DELAY_TIME['200_MS']);

      return;
    }

    this.stopNarration();
    this.saveAnswer();
    this.moveToNextQuestion();
    this.updateProgress();

    if (this.currentOption === 'speechOn') {
      this.startRecording();
    }
    this.questionIsRequired = false;
  }

  private moveToNextQuestion() {
    this.currentQuestionIndex++;
    if (this.currentQuestionIndex >= this.questions?.length) {
      this.showSummary();
    } else {
      this.askNextQuestion();
    }
  }

  previousQuestion() {
    this.stopNarration();
    if (this.currentQuestionIndex > 0) {
      this.currentQuestionIndex--;
      this.answers.pop();
      this.updateProgress();
    }
  }

  private updateProgress() {
    const nextProgress =
      (this.currentQuestionIndex / this.questions.length) *
      MAGIC_NUMBERS['100'];
    this.animateProgress(this.progress, nextProgress);
    this.progress = nextProgress;
  }

  private animateProgress(start: number, end: number) {
    const duration = MAGIC_NUMBERS['500']; // Duration of the animation in milliseconds
    const frameDuration = MAGIC_NUMBERS['500'] / MAGIC_NUMBERS['60']; // Assuming 60 FPS
    const totalFrames = duration / frameDuration;
    const step = (end - start) / totalFrames;
    let currentProgress = start;
    let frame = 0;

    const interval = setInterval(() => {
      currentProgress += step;
      this.setProgress(currentProgress);
      frame++;
      if (frame >= totalFrames) {
        clearInterval(interval);
        this.setProgress(end); // Ensure it ends exactly at the target value
      }
    }, frameDuration);
  }

  private setProgress(progress: number) {
    const circularProgressElement = document.querySelector(
      '.circular-progress'
    ) as HTMLElement;
    if (circularProgressElement) {
      circularProgressElement.style.setProperty('--progress', `${progress}`);
      circularProgressElement.style.background = `conic-gradient(#0077b6 0% ${progress}%, #e0e0e0 ${progress}% 100%)`;
    }
  }

  submitSurvey() {
    this.notificationService.showNotification(
      '🎉 Survey submitted successfully! 🎉',
      'success'
    );
    this.currentStep = 'landing';

    if (this.currentOption === 'bothOff' || this.currentOption === 'speechOn') {
      this.reloadModal();
      return;
    }

    const text = `Survey Submitted Successfully.`;
    this.ttsService.generateSpeech(text).subscribe({
      next: (blob) => this.playAudioBlob(blob, this.reloadModal.bind(this)),
      error: (err) => this.loggerService.displayLog(err),
    });
  }

  startRecording() {
    if (
      !this.isPermission ||
      this.currentOption === 'bothOff' ||
      this.currentOption === 'narrationOn' ||
      this.currentQuestionIndex >= this.questions?.length
    ) {
      return;
    }

    this.showAudioViz = true;

    of({}).subscribe(() => {
      this.voiceRecognition.handleRecording(this.destroy$).subscribe({
        next: (transcript) => {
          this.voiceInput = this.processInput(transcript);
          this.submitSpeech();
          this.showAudioViz = false;
        },
        error: (err) => this.loggerService.displayLog(err),
        complete: () => this.stopRecording(),
      });
    });
  }

  submitSpeech() {
    if (!this.voiceInput.length) return;
    this.webSocketService.send({
      question: `Question: ${this.questions[this.currentQuestionIndex].question}
      Options: ${this.questions[this.currentQuestionIndex].options}
      User: ${this.voiceInput}`,
    });
  }

  getSocketInformation() {
    this.webSocketService.socket$.subscribe({
      next: (res: TypeRes) => {
        this.stopRecording();
        this.handleResponse(res.answer as string);
      },
      error: (error) => {
        this.loggerService.displayLog(error);
        this.stopRecording();
      },
    });
  }

  handleResponse(response: string) {
    switch (response) {
      case 'A':
      case 'B':
      case 'C':
      case 'D':
        this.selectOption(
          this.questions[this.currentQuestionIndex].options[
            response.charCodeAt(0) - 'A'.charCodeAt(0)
          ]
        );
        break;
      case 'none':
      default:
        this.askAgain();
        break;
    }
  }

  completeQuestion() {
    if (this.currentOption === 'bothOff') {
      return;
    } else if (this.currentOption === 'speechOn') {
      setTimeout(() => {
        this.nextQuestion();
      }, DELAY_TIME['1000_MS']);
      return;
    }

    const lastChunk = this.getLastChunkText();
    const text = `You have selected option ${this.selectedOption}, ${lastChunk}`;
    this.ttsService.generateSpeech(text).subscribe({
      next: (blob) => this.playAudioBlob(blob, this.nextQuestion.bind(this)),
      error: (err) => this.loggerService.displayLog(err),
    });
  }

  askAgain() {
    if (this.currentOption === 'speechOn') {
      this.startRecording();
    } else {
      if (
        this.currentOption === 'bothOff' ||
        this.currentOption === 'speechOn'
      ) {
        return;
      }

      const text = `I didn't catch that.
    Could you please repeat your answer for the question: ${this.questions[this.currentQuestionIndex].question}?`;
      this.ttsService.generateSpeech(text).subscribe({
        next: (blob) =>
          this.playAudioBlob(blob, this.startRecording.bind(this)),
        error: (err) => this.loggerService.displayLog(err),
      });
    }
  }

  stopRecording() {
    this.voiceInput = '';
    this.cancelOngoingRequests();
    this.voiceRecognition.cleanup();
  }

  cancelOngoingRequests() {
    this.destroy$.next();
  }

  getMicPermission() {
    navigator.mediaDevices
      .getUserMedia({ audio: { noiseSuppression: true } })
      .then((stream) => {
        this.isActiveStream = stream.active;
        this.isPermission = this.isActiveStream;
        this.isDecline = false;
        localStorage.setItem('userVisited', JSON.stringify(this.isPermission));
        this.reloadModal();
      })
      .catch((error) => {
        this.isPermission = false;
        this.isDecline = true;

        this.loggerService.displayLog(error);
      });
  }

  reloadModal() {
    location.reload();
  }

  processInput(input: string): string {
    return input.replace(/\$/g, '');
  }

  private stopNarration() {
    if (this.currentAudio) {
      this.currentAudio.pause();
      this.currentAudio.currentTime = 0;
      this.currentAudio = null;
      this.cancelOngoingRequests();
    }
  }

  private saveAnswer() {
    this.answers.push({
      question: this.questions[this.currentQuestionIndex].question,
      answer: this.selectedOption,
    });
    this.selectedOption = null;
  }

  private showSummary() {
    this.currentStep = 'summary';
    if (this.currentOption === 'bothOff' || this.currentOption === 'speechOn') {
      return;
    }
    const text = `The next is summary. Please review your answers.`;
    this.ttsService.generateSpeech(text).subscribe({
      next: (blob) =>
        this.playAudioBlob(blob, () => {
          const text = `Please submit your survey. Thank you for your time.`;
          this.ttsService.generateSpeech(text).subscribe({
            next: (blob) => this.playAudioBlob(blob),
            error: (err) => this.loggerService.displayLog(err),
          });
        }),
      error: (err) => this.loggerService.displayLog(err),
    });
  }

  private askNextQuestion() {
    if (this.currentOption === 'bothOff' || this.currentOption === 'speechOn') {
      return;
    }
    const question = this.questions[this.currentQuestionIndex].question;
    const options =
      this.questions[this.currentQuestionIndex].options.join(', ');
    const text = `The next question is ${question}, and the options are ${options}.`;
    this.ttsService.generateSpeech(text).subscribe({
      next: (blob) => this.playAudioBlob(blob, this.startRecording.bind(this)),
      error: (err) => this.loggerService.displayLog(err),
    });
  }

  private getLastChunkText(): string {
    if (this.currentQuestionIndex === this.questions.length - 1) {
      return 'lets check your submitted answers.';
    } else if (
      this.currentQuestionIndex ===
      this.questions.length - MAGIC_NUMBERS['2']
    ) {
      return 'lets go to the last question';
    } else {
      return 'lets continue with our next question.';
    }
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
    if (this.isMenuOpen) {
      this.globalClickListener = this.renderer.listen(
        'document',
        'click',
        (event: Event) => {
          if (
            !event.target ||
            !(event.target as HTMLElement).closest('.menu')
          ) {
            this.isMenuOpen = false;
            this.globalClickListener();
          }
        }
      );
    }
  }

  setOption(option: string) {
    this.currentOption = option;
    this.isMenuOpen = false;

    if (this.currentOption === 'bothOff' || this.currentOption === 'speechOn') {
      this.welcomeBlobReady = true;
      this.textToSpeakReady = true;
    }

    // switch (option) {
    //   case 'bothOn':
    //     this.enableNarration();
    //     break;
    //   case 'bothOff':
    //     this.disableNarration();
    //     this.disableSpeech();
    //     break;
    //   case 'narrationOn':
    //     this.enableNarration();
    //     this.disableSpeech();
    //     break;
    //   case 'speechOn':
    //     this.disableNarration();
    //     this.enableSpeech();
    //     break;
    // }
  }

  enableNarration() {
    this.showAudioViz = false;
    this.stopRecording();

    if (!this.currentAudio) {
      this.ttsService.generateSpeech(this.getCurrentQuestionText()).subscribe({
        next: (blob) =>
          this.playAudioBlob(blob, this.startRecording.bind(this)),
        error: (err) => this.loggerService.displayLog(err),
      });
    }
  }

  disableNarration() {
    this.stopNarration();
  }

  enableSpeech() {
    this.startRecording();
  }

  disableSpeech() {
    this.stopRecording();
  }

  getCurrentQuestionText(): string {
    const question = this.questions[this.currentQuestionIndex].question;
    const options =
      this.questions[this.currentQuestionIndex].options.join(', ');
    return `The question is ${question}, and the options are ${options}.`;
  }

  ngOnDestroy(): void {
    this.cancelOngoingRequests();
    if (this.globalClickListener) {
      this.globalClickListener();
    }
    this.wakeLockService.releaseWakeLock();
  }
}
