import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OpenAIConfig } from '@constants/common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TextToSpeechService {
  private apiUrl = 'https://api.openai.com/v1/audio/speech';

  constructor(private http: HttpClient) {}

  generateSpeech(text: string): Observable<Blob> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${OpenAIConfig.apiKey}`,
      'Content-Type': 'application/json',
    });

    const body = {
      model: 'tts-1',
      input: text,
      voice: 'alloy',
    };

    return this.http.post(this.apiUrl, body, {
      headers: headers,
      responseType: 'blob', // This is important to handle binary data
    });
  }
}
