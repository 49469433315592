import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  NgClass,
  NgForOf,
  NgIf,
  NgSwitch,
  NgSwitchCase,
} from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DELAY_TIME, MAGIC_NUMBERS } from '@constants/common';
import {
  ChatMessage,
  ComponentState,
  TypeRes,
} from '@models/speech-recognition.model';
import { AudioVisualizerComponent } from '@pages/audio-visualizer/audio-visualizer.component';
import { AudioVisualizerService } from '@services/audio-visualizer.service';
import { LoggerService } from '@services/logger.service';
import { StateManagementService } from '@services/state-management.service';
import { VoiceRecognitionService } from '@services/voice-recognition.service';
import { WebSocketService } from '@services/web-socket.service';
import { ThinkingLoaderComponent } from '@shared/thinking-loader/thinking-loader.component';
import { Subject, Subscription, delay, of } from 'rxjs';

@Component({
  selector: 'app-audio-chat',
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    NgClass,
    NgForOf,
    ThinkingLoaderComponent,
    HttpClientModule,
    NgSwitch,
    NgSwitchCase,
    AudioVisualizerComponent,
  ],
  templateUrl: './audio-chat.component.html',
  styleUrls: ['./audio-chat.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 1, transform: 'translateY(0)' })),
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(20px)' }),
        animate('400ms ease-out'),
      ]),
      transition(':leave', [
        animate(
          '400ms ease-out',
          style({ opacity: 0, transform: 'translateY(-20px)' })
        ),
      ]),
    ]),
  ],
})
export class AudioChatComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('suggestionList1') suggestionList1: ElementRef;
  @ViewChild('suggestionList2') suggestionList2: ElementRef;
  @ViewChild('chatHistoryContainer', { static: false })
  private chatHistoryContainer: ElementRef<HTMLDivElement>;

  audioSrc = '';
  showWelcomeMessage = true;
  hasChatHistory = false;
  showChat = false;
  inputText = '';
  showSendButton = false;
  currentState: ComponentState = 'welcome';
  isPermission = false;
  isDecline = false;
  isActiveStream = true;
  isDisconnectAlert = false;
  answer = '';
  voiceInput = '';
  baseUrl = '';
  chatHistory: ChatMessage[] = [];
  messageIndex = 0;
  messages = ['Say “Hello!” to Haxi'];
  starterMessages = [
    '🤝 How can I contact ViitorCloud Technology for sales or support?',
    '💼 What services does ViitorCloud Technology offer?',
    '🎁 What are the benefits of using ViitorCloud product or service?',
    '🔒 How does ViitorCloud Technology protect my data?',
    '👥 What is the size and expertise of ViitorCloud IT team?',
  ];
  currentInputMode: 'text' | 'speech' = 'text';
  showAnimatedBG = false;
  isProcessing = false; // New flag to track processing state

  private audioQueue: {
    index?: number;
    audioBlob?: string;
    answer: string;
  }[] = [];
  private audioContext: AudioContext;
  private currentSource: AudioBufferSourceNode | null = null;
  private isPlaying = false;
  private isCompleted = false;
  private playedChunks = new Set<number>();
  private accumulatedMessage = ''; // To accumulate message chunks
  private currentMessageIndex: number | null = null;
  private destroy$ = new Subject<void>();
  private stateSub: Subscription;
  private intervalId: NodeJS.Timeout;
  private stopTextRendering = false;

  constructor(
    private stateService: StateManagementService,
    private voiceRecognition: VoiceRecognitionService,
    private loggerService: LoggerService,
    private webSocketService: WebSocketService,
    private cdr: ChangeDetectorRef,
    private visualizerService: AudioVisualizerService
  ) {}

  ngOnInit(): void {
    this.initializeComponentState();
    this.initializeMessageRotation();
    this.audioContext = new AudioContext();

    // Connect to WebSocket initially
    this.connectWebSocket();
    this.visualizerService.changeColor('red');
  }

  private connectWebSocket(): void {
    this.webSocketService.connect('audio');
  }

  private initializeComponentState() {
    this.baseUrl = window.location.origin;
    this.hasChatHistory = !!this.chatHistory.length;
    this.isPermission = JSON.parse(
      localStorage.getItem('userVisited') ?? 'false'
    );

    this.stateSub = this.stateService.viewState$.subscribe((state) => {
      this.currentState = state;
    });
  }

  private initializeMessageRotation() {
    this.intervalId = setInterval(() => {
      this.messageIndex = (this.messageIndex + 1) % this.messages.length;
    }, DELAY_TIME['3000_MS']);
  }

  ngAfterViewInit() {
    this.setInitialScrollPosition();
    this.enableSmoothScroll(this.suggestionList1.nativeElement);
    this.enableSmoothScroll(this.suggestionList2.nativeElement);
  }

  setInitialScrollPosition() {
    if (this.suggestionList1?.nativeElement) {
      this.suggestionList1.nativeElement.scrollLeft = 10;
    }

    if (this.suggestionList2?.nativeElement) {
      this.suggestionList2.nativeElement.scrollLeft = 300;
    }
  }

  enableSmoothScroll(element: HTMLElement) {
    element.addEventListener(
      'wheel',
      (evt) => {
        evt.preventDefault();
        element.scrollLeft += evt.deltaY;
        element.style.scrollBehavior = 'smooth';
      },
      { passive: false }
    );

    element.addEventListener('scroll', () => {
      element.style.scrollBehavior = 'auto';
    });
  }

  private setSpeechInputMode() {
    this.currentInputMode = 'speech';
  }

  startRecording() {
    if (!this.isPermission || this.isProcessing) return;

    this.setSpeechInputMode();

    of({})
      .pipe(delay(DELAY_TIME['500_MS']))
      .subscribe(() => {
        this.startListening();

        let inactivityTimer: ReturnType<typeof setTimeout>;

        const resetInactivityTimer = () => {
          if (inactivityTimer) {
            clearTimeout(inactivityTimer);
          }
          inactivityTimer = setTimeout(() => {
            this.loggerService.displayLog(
              'Inactivity detected, stopping recording.',
              'log'
            );
            this.stopRecordingOnInactivity();
          }, DELAY_TIME['10000_MS'] * MAGIC_NUMBERS['6']); // 1 minute of inactivity
        };

        resetInactivityTimer(); // Initialize the timer when recording starts

        this.voiceRecognition.handleRecording(this.destroy$).subscribe({
          next: (transcript) => {
            this.voiceInput = transcript;
            this.submitSpeech();
            resetInactivityTimer(); // Reset the timer on valid transcription
          },
          error: (err) => {
            this.loggerService.displayLog(err);
            resetInactivityTimer(); // Reset the timer on error to keep recording
          },
          complete: () => {
            this.stopRecording();
            clearTimeout(inactivityTimer); // Clear the timer when recording is stopped
          },
        });
      });
  }

  stopRecordingOnInactivity(): void {
    this.completeRecording();
  }

  submitSpeech() {
    if (
      (!this.inputText.trim().length && !this.voiceInput.length) ||
      this.isProcessing
    ) {
      return;
    }

    this.isProcessing = true;
    this.currentInputMode = this.inputText.length ? 'text' : 'speech';
    this.stopTextRendering = false; // Reset the text rendering flag

    const message = this.voiceInput || this.inputText;
    this.chatHistory.push({ sender: 'user', message });
    this.hasChatHistory = true;
    this.responseSubmitted();
    this.scrollToBottom();

    this.startThinking();
    this.webSocketService.send({ question: message });
    this.getSocketInformation();

    this.voiceInput = '';
    this.inputText = '';
  }

  startThinking() {
    this.stateService.setViewState('thinking');
  }

  getSocketInformation() {
    this.webSocketService.socket$.subscribe({
      next: (res: TypeRes) => {
        if (res.audioBlob && !this.playedChunks.has(res.audioBlob.index)) {
          this.playedChunks.add(res.audioBlob.index);
          this.audioQueue.push({
            index: res.audioBlob.index,
            audioBlob: res.audioBlob.audio_blob,
            answer: res.answer as string, // Save the answer chunk with the audio chunk
          });
          this.audioQueue.sort(
            (a, b) => (a.index as number) - (b.index as number)
          ); // Ensure the queue is ordered by index
          if (!this.isPlaying) {
            this.isPlaying = true;
            this.playNextChunk();
          }
        }

        if (res.status === 'completed') {
          this.isCompleted = true;
          if (!this.isPlaying) {
            this.onAllChunksPlayed();
          }
        }
      },
      error: (error) => this.loggerService.displayLog(error),
    });
  }

  private async playNextChunk(): Promise<void> {
    if (this.audioQueue.length === 0) {
      this.isPlaying = false;
      if (this.isCompleted) {
        this.onAllChunksPlayed();
      }
      return;
    }

    this.isPlaying = true;
    const currentChunk = this.audioQueue.shift();

    if (!currentChunk) {
      this.isPlaying = false;
      return;
    }

    const { audioBlob, answer } = currentChunk;

    try {
      // Decode the base64 string to an ArrayBuffer
      const arrayBuffer = this.base64ToArrayBuffer(audioBlob as string);
      const audioBuffer = await this.audioContext.decodeAudioData(arrayBuffer);

      // Play the audio buffer and animate the text chunk simultaneously
      await Promise.all([
        this.playAudioBuffer(audioBuffer),
        this.updateTextChunk(`${answer} `),
      ]);
    } catch (error) {
      this.loggerService.displayLog('Error decoding audio chunk:' + error);
      this.audioQueue.unshift(currentChunk); // Re-add the chunk to the front of the queue
      setTimeout(() => this.playNextChunk(), DELAY_TIME['1000_MS']); // Retry after a delay
    }
  }

  // Helper method to convert base64 string to ArrayBuffer
  private base64ToArrayBuffer(base64: string): ArrayBuffer {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  private async updateTextChunk(answer: string): Promise<void> {
    if (this.currentMessageIndex === null) {
      // Add new message to chatHistory
      this.currentMessageIndex = this.chatHistory.length;
      this.chatHistory.push({
        sender: 'server',
        message: '',
      });
    }
    await this.typeTextEffect(answer, this.currentMessageIndex);
  }

  private typeTextEffect(
    text: string,
    index: number,
    delay: number = 50
  ): Promise<void> {
    return new Promise((resolve) => {
      let i = 0;
      const typeNextCharacter = () => {
        if (this.stopTextRendering) {
          resolve(); // Stop rendering text if the flag is set
          return;
        }
        if (i < text.length) {
          this.chatHistory[index].message += text[i];
          this.scrollToBottom();
          i++;
          this.cdr.detectChanges();
          setTimeout(typeNextCharacter, delay);
        } else {
          resolve();
        }
      };
      typeNextCharacter();
    });
  }

  private playAudioBuffer(audioBuffer: AudioBuffer): Promise<void> {
    return new Promise((resolve) => {
      this.currentSource = this.audioContext.createBufferSource();
      this.currentSource.buffer = audioBuffer;
      this.currentSource.connect(this.audioContext.destination);
      this.currentSource.onended = async () => {
        if (!this.stopTextRendering) {
          this.isPlaying = false;
          resolve();
          await this.playNextChunk();
        }
      };
      this.currentSource.start();

      this.stateService.setViewState('speaking');
    });
  }

  private onAllChunksPlayed(): void {
    this.resetAudioElement();
    this.isCompleted = false;
    this.playedChunks.clear(); // Clear played chunks for the next session
    this.accumulatedMessage = ''; // Reset accumulated message
    this.currentMessageIndex = null; // Reset current message index

    if (this.currentInputMode === 'speech') {
      this.startRecording();
    } else {
      this.stateService.setViewState('none');
    }
  }

  stopAudio() {
    this.stopTextRendering = true; // Stop text rendering
    if (this.currentSource) {
      this.currentSource.stop();
    }
    this.resetAudioElement();
    this.isCompleted = false;
    this.isPlaying = false; // Ensure isPlaying is set to false to stop playback
    this.playedChunks.clear();
    this.accumulatedMessage = ''; // Reset accumulated message
    this.currentMessageIndex = null; // Reset current message index

    // Disconnect the WebSocket to stop receiving new chunks
    this.webSocketService.disconnect();

    this.stateService.setViewState('none');

    setTimeout(() => {
      // Reconnect WebSocket after stopping audio
      this.connectWebSocket();
    }, DELAY_TIME['500_MS']);
  }

  private resetAudioElement() {
    this.isProcessing = false;
    this.audioQueue = []; // Clear the audio queue

    if (this.currentSource) {
      this.currentSource.disconnect();
      this.currentSource = null;
    }
  }

  audioEnded(): void {
    this.resetAudioElement();

    if (this.currentInputMode === 'speech') {
      this.startRecording();
    }
  }

  responseSubmitted() {
    if (this.hasChatHistory) this.toggleChat();
  }

  completeRecording() {
    this.stateService.setViewState('none');
    this.stopRecording();
  }

  private stopRecording() {
    this.voiceInput = '';
    this.cancelOngoingRequests();
    this.voiceRecognition.cleanup();
  }

  cancelOngoingRequests() {
    this.destroy$.next();
  }

  resetRecording() {
    this.voiceRecognition.stopRecording();

    this.voiceInput = '';
    this.resetControls();
    setTimeout(() => this.startRecording());
  }

  reloadModal() {
    location.reload();
  }

  toggleChat(): void {
    this.showChat = !this.showChat;
    this.stateService.setViewState(this.showChat ? 'chat' : 'welcome');
  }

  showWelcome() {
    this.stateService.setViewState('welcome');
  }

  startListening() {
    this.stateService.setViewState(
      this.currentState === 'listening' ? 'none' : 'listening'
    );
  }

  checkInput(): void {
    this.showSendButton = !!this.inputText.length;
  }

  shouldShowStopListening(): boolean {
    return ['listening', 'thinking'].includes(this.currentState);
  }

  getMicPermission() {
    navigator.mediaDevices
      .getUserMedia({ audio: { noiseSuppression: true } })
      .then((stream) => {
        this.isActiveStream = stream.active;
        this.isPermission = this.isActiveStream;
        this.isDecline = false;
        localStorage.setItem('userVisited', JSON.stringify(this.isPermission));
        this.reloadModal();
      })
      .catch((error) => {
        this.isPermission = false;
        this.isDecline = true;
        this.loggerService.displayLog(error);
      });
  }

  resetControls() {
    this.stateService.setViewState('welcome');
    this.showChat = false;
  }

  setInputText(message: string): void {
    this.inputText = message;
    this.submitSpeech();
  }

  scrollToBottom(): void {
    setTimeout(() => {
      const element = this.chatHistoryContainer.nativeElement;
      if (element) {
        element.scrollTop = element.scrollHeight;
      }
    }, DELAY_TIME['100_MS']);
  }

  ngOnDestroy() {
    localStorage.removeItem('userVisited');
    clearInterval(this.intervalId);
    this.destroy$.next();
    this.destroy$.complete();
    this.stateSub.unsubscribe();
    this.webSocketService.disconnect();
  }
}
