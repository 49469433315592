export const ErrorConstants = {
  BadRequest: 400,
  NotFound: 404,
};

export const OpenAIConfig = {
  baseUrl: 'https://api.openai.com/v1/audio/transcriptions',
  apiKey: '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',
  model: 'whisper-1',
  fileType: 'audio/wav',
};

export const DeepgramConfig = {
  apiKey: '4320f5c6375cd04c4d124cafafa1c8bf5a0b0da3',
};

export const DELAY_TIME = {
  '12_HR': 12,
  '3600_SEC': 3600,
  '100_MS': 100,
  '200_MS': 200,
  '500_MS': 500,
  '1000_MS': 1000,
  '1500_MS': 1500,
  '2000_MS': 2000,
  '3000_MS': 3000,
  '4000_MS': 4000,
  '10000_MS': 10000,
};

export const MAGIC_NUMBERS = {
  0.5: 0.5,
  0.75: 0.75,
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
  10: 10,
  15: 15,
  60: 60,
  100: 100,
  128: 128,
  500: 500,
};

export const DEFAULT_PARTICLE =
  'https://vc-development-public.s3.ap-south-1.amazonaws.com/hologram-stage-viewer-application/particale_01.prefab';

export const EXPIRY_DURATION =
  DELAY_TIME['12_HR'] * DELAY_TIME['3600_SEC'] * DELAY_TIME['1000_MS']; // 12 hours in milliseconds

export const UUID_KEY_AUDIO = 'haxi-uuid-audio';
export const UUID_KEY_VIDEO = 'haxi-uuid-video';
export const EXPIRY_KEY_AUDIO = 'haxi-uuid-expiry-audio';
export const EXPIRY_KEY_VIDEO = 'haxi-uuid-expiry-video';
