<div class="container">
  <!-- Header -->
  <header
    class="w-full flex justify-between items-center p-2 lg:p-4 fixed top-0 left-0 right-0 z-50"
  >
    <a href="https://www.viitorcloud.com" target="_blank">
      <img
        src="assets/images/viitorcloud-logo.png"
        alt="Company Logo"
        class="h-8"
      />
    </a>
    <ng-container *ngIf="false">
      <div class="menu h-8 w-8 relative cursor-pointer tooltip-container">
        <img
          tabindex="-1"
          src="assets/images/svg/settings-icon.svg"
          alt="settings"
          (click)="toggleMenu()"
          (keydown.enter)="toggleMenu()"
        />
        <div
          *ngIf="isMenuOpen"
          class="menu-content bg-lightest-blue rounded-md absolute right-0 mt-2 w-56 text-white shadow-lg z-50"
        >
          <div class="menu-arrow"></div>
          <div class="grid grid-cols-2 gap-2 p-2">
            <!-- Narration and Speech both on -->
            <div
              tabindex="-1"
              class="menu-item"
              [ngClass]="{ selected: currentOption === 'bothOn' }"
              (click)="setOption('bothOn')"
              (keydown.enter)="setOption('bothOn')"
            >
              <img
                class="h-7 w-7"
                src="assets/images/svg/volume-on-ss.svg"
                alt="volume"
              />
              <img
                class="h-7 w-7"
                src="assets/images/svg/mic-on-ss.svg"
                alt="mic"
              />
            </div>
            <!-- Narration and Speech both off -->
            <div
              tabindex="-1"
              class="menu-item"
              [ngClass]="{ selected: currentOption === 'bothOff' }"
              (click)="setOption('bothOff')"
              (keydown.enter)="setOption('bothOff')"
            >
              <img
                class="h-7 w-7"
                src="assets/images/svg/volume-off-ss.svg"
                alt="volume"
              />
              <img
                class="h-7 w-7"
                src="assets/images/svg/mic-off-ss.svg"
                alt="mic"
              />
            </div>
            <!-- Narration on and Speech off -->
            <div
              tabindex="-1"
              class="menu-item"
              [ngClass]="{ selected: currentOption === 'narrationOn' }"
              (click)="setOption('narrationOn')"
              (keydown.enter)="setOption('narrationOn')"
            >
              <img
                class="h-7 w-7"
                src="assets/images/svg/volume-on-ss.svg"
                alt="volume"
              />
              <img
                class="h-7 w-7"
                src="assets/images/svg/mic-off-ss.svg"
                alt="mic"
              />
            </div>
            <!-- Narration off and Speech on -->
            <div
              tabindex="-1"
              class="menu-item"
              [ngClass]="{ selected: currentOption === 'speechOn' }"
              (click)="setOption('speechOn')"
              (keydown.enter)="setOption('speechOn')"
            >
              <img
                class="h-7 w-7"
                src="assets/images/svg/volume-off-ss.svg"
                alt="volume"
              />
              <img
                class="h-7 w-7"
                src="assets/images/svg/mic-on-ss.svg"
                alt="mic"
              />
            </div>
          </div>
        </div>
        <div
          class="w-max p-2 bg-[#00EDD4] text-dark-blue rounded-md absolute z-10 top-[140%] right-[2px] opacity-100 tooltip"
        >
          <div class="tooltip-arrow"></div>
          Disable the audio related features from here.
        </div>
      </div>
    </ng-container>
  </header>

  <!-- Main Container -->
  <div
    class="main-container flex-grow flex flex-col items-center justify-center p-4"
  >
    <!-- Landing Page -->
    <div
      *ngIf="currentStep === 'landing'"
      class="flex flex-col-reverse lg:flex-row items-center text-center lg:text-left gap-3 md:gap-6 lg:gap-16"
    >
      <div
        class="flex flex-col justify-center items-center lg:items-start w-full lg:w-1/2"
      >
        <h1
          class="text-[#00B4D8] text-3xl md:text-4xl lg:text-6xl font-bold mb-4"
        >
          Welcome to Smart Survey
        </h1>
        <p class="text-[#CAF0F8] text-base md:text-lg mb-4 w-full lg:w-8/12">
          This survey will take approximately 5 minutes to complete. Please read
          the instructions carefully before starting.
        </p>
        <button
          (click)="startSurvey()"
          [disabled]="!textToSpeakReady"
          class="duration-300 ease-in-out w-40 md:w-48 font-semibold z-[100] flex justify-center items-center bg-gradient-to-r text-dark-blue py-3 px-4 md:py-4 md:px-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 disabled:opacity-50 disabled:cursor-not-allowed from-blue via-light-blue to-lightest-blue"
        >
          @if (textToSpeakReady) {
            Start Your Survey
          } @else {
            <div class="sk-chase">
              <div class="sk-chase-dot"></div>
              <div class="sk-chase-dot"></div>
              <div class="sk-chase-dot"></div>
              <div class="sk-chase-dot"></div>
              <div class="sk-chase-dot"></div>
              <div class="sk-chase-dot"></div>
            </div>
          }
        </button>
      </div>
      <div class="w-full lg:w-1/2 flex justify-center lg:justify-end relative">
        <div class="blob absolute inset-0"></div>
        <img
          src="assets/images/svg/survey-landing-page.svg"
          alt="Survey"
          class="h-60 sm:h-[30rem] lg:h-[42rem] max-h-svh relative z-10"
        />
      </div>
    </div>

    <!-- Survey Questions -->
    <div
      *ngIf="currentStep === 'question'"
      class="w-full max-w-4xl flex flex-col lg:flex-row gap-4 px-5 md:px-0"
    >
      <ng-container *ngIf="currentOption !== 'bothOff'">
        <!-- Block 1 -->
        <div
          class="flex flex-col items-center justify-center bg-transparent rounded-lg p-4 md:pr-8 w-full lg:w-1/2"
        >
          <span class="w-full h-12 md:h-full flex justify-center items-center">
            @if (
              showAudioViz &&
              (currentOption === "bothOn" || currentOption === "speechOn")
            ) {
              <app-audio-visualizer class="w-full"></app-audio-visualizer>
            } @else if (
              !showAudioViz &&
              (currentOption === "bothOn" || currentOption === "narrationOn")
            ) {
              <img
                class="w-24 h-24"
                src="assets/images/listening.gif"
                alt="Listening"
              />
            }
          </span>
        </div>
      </ng-container>

      <!-- Block 2 -->
      <div
        #questionContainer
        [ngClass]="{
          'horizontal-shake border-4 border-red-700': questionIsRequired
        }"
        class="block-2 w-full bg-white rounded-lg p-4 lg:p-6 shadow-lg relative"
      >
        <!-- Circular Progress Indicator -->
        <div
          class="circular-progress-container absolute top-[-2rem] right-[-2rem]"
        >
          <div class="circular-progress" [attr.data-progress]="progress"></div>
          <div class="progress-text">{{ progress | number: "1.0-0" }}%</div>
        </div>

        <div
          class="flex justify-between items-center mb-2 lg:mb-4 text-[#03045E]"
        >
          <p class="text-base lg:text-lg">
            Question {{ currentQuestionIndex + 1 }} of {{ questions.length }}
          </p>
        </div>
        <h2 class="text-xl lg:text-3xl font-bold mb-4 lg:mb-6 text-[#03045E]">
          {{ questions[currentQuestionIndex].question || "" }}
          <span
            *ngIf="questions[currentQuestionIndex].required"
            class="text-red-700"
            >*</span
          >
        </h2>
        <div class="grid grid-cols-1 gap-2 lg:gap-4 mb-4 lg:mb-6">
          <div
            tabindex="-1"
            (keydown)="selectOption(option)"
            *ngFor="let option of questions[currentQuestionIndex].options"
            (click)="selectOption(option)"
            [ngClass]="{
              'selected-option': selectedOption === option,
              'unselected-option': selectedOption !== option
            }"
            class="options-card cursor-pointer p-2 lg:p-4 rounded-lg shadow hover:bg-[#CAF0F8] hover:text-gray-900 transition"
          >
            {{ option }}
          </div>
        </div>
        <div class="flex justify-between w-full">
          <div class="flex-grow"></div>
          <button
            (click)="nextQuestion()"
            class="bg-[#0077B6] text-white p-2 rounded-full hover:bg-[#00B4D8] transition-transform hover:scale-105"
          >
            <img
              class="h-4 lg:h-6"
              src="assets/images/svg/next-arrow.svg"
              alt="Next"
            />
          </button>
        </div>
      </div>
    </div>

    <!-- Survey Summary -->
    <div
      *ngIf="currentStep === 'summary'"
      class="w-full md:w-4/5 bg-white rounded-lg lg:py-4 shadow-lg relative flex flex-col h-[calc(100vh-160px)]"
    >
      <!-- Card Title -->
      <h2 class="text-3xl font-medium text-[#03045E] p-4">Summary</h2>

      <!-- Card Content -->
      <div class="flex-grow overflow-y-auto px-4 lg:px-6">
        <div
          *ngFor="let answer of answers; let i = index"
          class="py-4"
          [ngClass]="{ 'border-b border-gray-300': i < answers.length - 1 }"
        >
          <h3 class="text-lg text-[#03045E] mb-2">
            {{ i + 1 }}. {{ answer.question }}
          </h3>
          <p class="text-base text-[#03045E] opacity-80">
            {{ answer.answer ? answer.answer : "Not answered" }}
          </p>
        </div>
      </div>

      <!-- Card Action -->
      <div class="p-4 text-center action-section-shadow">
        <button
          (click)="submitSurvey()"
          class="w-40 bg-[#0077B6] text-white px-6 py-3 rounded-full shadow hover:bg-[#00B4D8] transition-transform transform hover:scale-105 text-lg font-semibold"
        >
          Submit
        </button>
      </div>
    </div>

    <!-- Footer -->
    <footer
      class="w-full flex justify-end items-center p-2 lg:p-4 fixed bottom-0 left-0 right-0 z-50 bg-transparent"
    >
      <div class="flex items-center text-sm md:text-xl text-[#CAF0F8] gap-2">
        Powered by
        <a href="https://chat.haxi.ai/" target="_blank">
          <img
            class="h-3 md:h-4"
            src="assets/images/svg/haxi_ai-logo.svg"
            alt="Haxi Logo"
          />
        </a>
      </div>
    </footer>
  </div>

  <ng-container *ngIf="!isPermission && isActiveStream">
    <div
      class="fixed inset-0 flex items-center justify-center px-3 bg-black bg-opacity-70 z-[200] animate-fadeIn"
      aria-labelledby="permissionTitle"
      aria-describedby="permissionDescription"
    >
      <div
        class="sm:w-[30rem] flex flex-col items-center justify-center bg-white p-8 rounded-lg text-center shadow-lg animate-slideDown"
      >
        <img
          src="assets/images/mic-animation.gif"
          alt="Microphone Animation"
          class="w-20 border-2 border-dark-blue rounded-full bg-lightest-blue"
        />
        <p
          id="permissionTitle"
          class="text-dark-blue font-semibold text-base sm:text-lg py-3 sm:py-4"
        >
          Do you grant
          <span class="text-blue">{{ baseUrl }}</span>
          permission to access your microphone?
        </p>
        <button
          class="bg-blue text-white px-6 py-2 rounded-full hover:bg-dark-blue transition duration-300 font-semibold"
          (click)="getMicPermission()"
        >
          Yes
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="openPopup">
    <div
      class="fixed inset-0 flex items-center justify-center px-3 bg-black bg-opacity-70 z-[200] animate-fadeIn"
      aria-labelledby="permissionTitle"
      aria-describedby="permissionDescription"
    >
      <div
        class="bg-white rounded-lg p-5 max-w-md w-full text-center shadow-lg md:mx-0"
      >
        <p class="text-base md:text-lg text-[#03045E] mb-4">
          You'll be participating in a short survey.
          <br />
          Please select your audio preferences before starting.
        </p>
        <div class="grid grid-cols-2 gap-4 mb-4">
          <div
            tabindex="-1"
            class="menu-item p-4 flex flex-col items-center justify-center border border-transparent rounded-lg transition-transform transform hover:scale-105 focus:outline-none focus:border focus:border-blue-500 focus:scale-105"
            [ngClass]="{ selected: currentOption === 'bothOn' }"
            (click)="setOption('bothOn')"
            (keydown.enter)="setOption('bothOn')"
          >
            <img
              class="h-7 w-7 mb-1"
              src="assets/images/svg/volume-on-ss.svg"
              alt="volume"
            />
            <img
              class="h-7 w-7"
              src="assets/images/svg/mic-on-ss.svg"
              alt="mic"
            />
            <span class="text-xs mt-2">Narration & Speech On</span>
          </div>
          <div
            tabindex="-1"
            class="menu-item p-4 flex flex-col items-center justify-center border border-transparent rounded-lg transition-transform transform hover:scale-105 focus:outline-none focus:border focus:border-blue-500 focus:scale-105"
            [ngClass]="{ selected: currentOption === 'bothOff' }"
            (click)="setOption('bothOff')"
            (keydown.enter)="setOption('bothOff')"
          >
            <img
              class="h-7 w-7 mb-1"
              src="assets/images/svg/volume-off-ss.svg"
              alt="volume"
            />
            <img
              class="h-7 w-7"
              src="assets/images/svg/mic-off-ss.svg"
              alt="mic"
            />
            <span class="text-xs mt-2">Narration & Speech Off</span>
          </div>
          <div
            tabindex="-1"
            class="menu-item p-4 flex flex-col items-center justify-center border border-transparent rounded-lg transition-transform transform hover:scale-105 focus:outline-none focus:border focus:border-blue-500 focus:scale-105"
            [ngClass]="{ selected: currentOption === 'narrationOn' }"
            (click)="setOption('narrationOn')"
            (keydown.enter)="setOption('narrationOn')"
          >
            <img
              class="h-7 w-7 mb-1"
              src="assets/images/svg/volume-on-ss.svg"
              alt="volume"
            />
            <img
              class="h-7 w-7"
              src="assets/images/svg/mic-off-ss.svg"
              alt="mic"
            />
            <span class="text-xs mt-2">Narration On, Speech Off</span>
          </div>
          <div
            tabindex="-1"
            class="menu-item p-4 flex flex-col items-center justify-center border border-transparent rounded-lg transition-transform transform hover:scale-105 focus:outline-none focus:border focus:border-blue-500 focus:scale-105"
            [ngClass]="{ selected: currentOption === 'speechOn' }"
            (click)="setOption('speechOn')"
            (keydown.enter)="setOption('speechOn')"
          >
            <img
              class="h-7 w-7 mb-1"
              src="assets/images/svg/volume-off-ss.svg"
              alt="volume"
            />
            <img
              class="h-7 w-7"
              src="assets/images/svg/mic-on-ss.svg"
              alt="mic"
            />
            <span class="text-xs mt-2">Narration Off, Speech On</span>
          </div>
        </div>
        <button
          [disabled]="!welcomeBlobReady"
          class="ready-button bg-gradient-to-r from-light-blue to-lighter-blue text-dark-blue font-semibold py-3 px-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 disabled:opacity-70 disabled:cursor-not-allowed"
          (click)="welcomeMessage()"
        >
          I'm Ready! 👍
        </button>
      </div>
    </div>
  </ng-container>
</div>
