<ng-container *ngIf="message">
  <div
    class="text-base w-11/12 sm:w-auto text-center fixed top-5 left-1/2 transform -translate-x-1/2 p-4 rounded-lg shadow-lg text-white font-semibold z-[150] animate-fadeInOut"
    [ngClass]="{
      'bg-blue': notificationType === 'success',
      'bg-green-500': notificationType === 'info',
      'bg-yellow-400 text-black': notificationType === 'warning',
      'bg-red-500': notificationType === 'error'
    }"
  >
    {{ message }}
  </div>
</ng-container>
