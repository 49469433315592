import { Injectable } from '@angular/core';
import { LoggerService } from '@services/logger.service';

@Injectable({
  providedIn: 'root',
})
export class WakeLockService {
  private wakeLock: WakeLockSentinel | null = null;

  constructor(private loggerService: LoggerService) {
    if ('wakeLock' in navigator) {
      document.addEventListener('visibilitychange', async () => {
        if (document.visibilityState === 'visible' && this.wakeLock !== null) {
          await this.requestWakeLock();
        }
      });
    }
  }

  async requestWakeLock() {
    try {
      if ('wakeLock' in navigator) {
        this.wakeLock = await navigator.wakeLock.request('screen');
        this.wakeLock.addEventListener('release', () => {
          this.loggerService.displayLog('Wake lock has been released', 'log');
        });
      }
    } catch (err) {
      this.loggerService.displayLog(err);
    }
  }

  async releaseWakeLock() {
    if (this.wakeLock !== null) {
      await this.wakeLock.release();
      this.wakeLock = null;
    }
  }
}
