<div
  class="min-h-screen bg-gradient-radial from-lighter-blue via-blue to-dark-blue flex items-center justify-center relative overflow-hidden px-2 sm:p-0"
>
  <div
    class="absolute inset-0 bg-gradient-to-tl from-transparent to-black opacity-50"
  ></div>
  <div class="relative z-10 text-center text-white">
    <h1 class="text-6xl font-extrabold mb-4 animate-bounce text-dark-blue">
      Oops!
    </h1>
    <p class="text-2xl mb-6 text-lightest-blue">
      We're currently unavailable. Please try again later.
    </p>
    <a
      href="/"
      class="inline-block bg-lightest-blue text-dark-blue py-3 px-6 rounded-full font-bold shadow-lg transform transition-transform duration-300 hover:scale-110"
    >
      Go to Home
    </a>
  </div>
</div>
